import { Button } from "@/components/ui/button"
import { useAuth } from "@/contexts/AuthProvider";

const UserPanel = () => {

  const { logout } = useAuth();

  return (
    <div>
      <Button onClick={logout}>
        Çıkış Yap
      </Button>
    </div>
  )
}

export default UserPanel